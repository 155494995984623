import React from "react";

const HeaderApp = (props) => {
  return (
    <>
      <div className="appHeader">{props.pageName}</div>
    </>
  );
};

export default HeaderApp;
