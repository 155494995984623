import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import AppAccessLayout from "../../components/appAccessLayout/AppAccessLayout";
import { Form } from "react-bootstrap";
import AppAccessBtn from "../../components/appAccessLayout/AppAccessBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../../Hooks/api/Api";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import Loader from "../../components/Loader/Loader";
import { errorMsg } from "../../Helpers/Error";

const LoginPassword = () => {
  const heading = "Existing User ?";
  const heading_sub = "Login";
  const formName = "loginForm";
  const btnName = "Login";
  const navigate = useNavigate();
  const currentNo = JSON.parse(localStorage.getItem("anotherNo"));

  const initialValues = {
    phoneNo: currentNo,
    password: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [isInvalid, setIsInvalid] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setFormValues({ ...formValues, [name]: value });
  };

  const formValid = () => {
    const formInputs = document.querySelectorAll("input");
    const validFormInputs = Array.from(formInputs).filter(
      (input) => input.value
    );
    if (validFormInputs.length === 2) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setLoader(true);
      axiosInstance
        .post("customers/login", formValues)
        .then((response) => {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data["data"])
          );
          localStorage.setItem("token", response.data.data.token.token);
          setLoader(false);
          navigate("/user/home", { replace: true });
          localStorage.setItem("login", true);
          localStorage.removeItem("anotherNo");
        })
        .catch((error) => {
          setLoader(false);
          setError(errorMsg(error));
          setTimeout(() => {
            setError("");
          }, 3000);
        });
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    setFormErrors(validate(formValues));
    e.preventDefault();
    setIsSubmit(true);
  };

  const validate = (values) => {
    const errors = {};
    const regex = /[0-9]{10}$/;
    if (!values.phoneNo) {
      errors.phoneNo = "Phone Number is required";
      document.getElementById("phoneNo").classList.add("border-danger");
    } else if (values.phoneNo.length < 10 || !regex.test(values.phoneNo)) {
      errors.phoneNo = "Invalid Phone Number. Please Check";
      document.getElementById("phoneNo").classList.add("border-danger");
    }
    if (!values.password) {
      errors.password = "Password is required";
      document.getElementById("password").classList.add("border-danger");
    }

    return errors;
  };
  useEffect(() =>{
    let login = localStorage.getItem('login');
    if (login){
      navigate('/user/home');
    }
  });

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0">
          <Card className="br-top elem-h-485 cardheight">
            <Card.Body className="d-flex align-items-center flex-column">
              <AppAccessLayout
                heading={heading}
                heading_sub={heading_sub}
              ></AppAccessLayout>
              <Form
                noValidate
                id={formName}
                className="mt-5 col-12 col-md-6 col-xl-6 text-center px-4"
                onSubmit={handleSubmit}
                onChange={formValid}
              >
                <Form.Control
                  type="tel"
                  autoComplete="off"
                  name="phoneNo"
                  id="phoneNo"
                  value={formValues.phoneNo ?? ""}
                  placeholder="Mobile Number"
                  className="mb-2 theme-shadow text-left"
                  required
                  onChange={handleChange}
                  maxLength="10"
                  pattern="^[0-9]{10}$"
                ></Form.Control>
                <small className="text-danger">{formErrors.phoneNo}</small>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  name="password"
                  id="password"
                  value={formValues.password}
                  placeholder="Password"
                  className="mb-2 theme-shadow text-left"
                  required
                  onChange={handleChange}
                ></Form.Control>
                <small className="text-danger">{formErrors.password}</small>
                <AppAccessBtn
                  formName={formName}
                  btnName={btnName}
                  disable={isInvalid}
                ></AppAccessBtn>
              </Form>
              <NavLink
                to={"/ResetPassword"}
                className="text-decoration-none fw-bold small text-dark mt-2"
              >
                Forgot Password ?
              </NavLink>
            </Card.Body>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default LoginPassword;
