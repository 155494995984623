import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import NavApp from "../../components/appAdminLayout/NavApp";
import Loader from "../../components/Loader/Loader";
import { errorMsg } from "../../Helpers/Error";
import axiosInstance from "../../Hooks/api/Api";
import { ChaptersCard } from "./ChaptersCard";

const CourseVideo = () => {
  const courseID = useParams();
  const showBars = false;
  const [title, setTitle] = useState("");
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [courseData, setCourseData] = useState({});
  const [chapters, setChapters] = useState([]);
  const getCourseInfo = () => {
    setLoader(true);
    axiosInstance
      .get("courses/getCourseDetails/" + courseID.id)
      .then((response) => {
        setCourseData(response.data.data);
        setChapters(response.data.data.chapters);
        setTitle(response.data.message);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        errorMsg(setError(error));
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  useEffect(() => {
    getCourseInfo();
  }, []);

  return (
    <>
      {loader && <Loader></Loader>}
      <NavApp title={title} showBars={showBars}></NavApp>
      <Container fluid className="p-0 wrapper bg-light min-vh-100">
        <Card className="text-center m-2">
          <div className="theme-shadow">
            <img
              src={courseData.media ? courseData.media : "/logo512.png"}
              alt={courseData.name}
              className="img-fluid"
              style={{ height: "150px" }}
            ></img>
          </div>
        </Card>
        <div className="video-containter noselect">
          {chapters &&
            chapters.map((chapter) => (
              <ChaptersCard
                key={chapter._id}
                thumbnail={chapter.videoDetails.thumbnail}
                name={chapter.name}
                description={chapter.description}
                videoUrl={chapter.videoDetails.preview}
              ></ChaptersCard>
            ))}
        </div>
      </Container>
    </>
  );
};

export default CourseVideo;
