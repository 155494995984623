import React from "react";
import { Button } from "react-bootstrap";

const AppAccessBtn = (props) => {
  return (
    <Button
      type="submit"
      className="border-0  mt-2 col-12 theme-shadow "
      variant="button-color-1 color-theme-white"
      form={props.formName}
      disabled={props.disable}
      id="submitButton"
    >
      {props.btnName}
    </Button>
  );
};

export default AppAccessBtn;
