import React from "react";
import { Card } from "react-bootstrap";
import { Stream } from "@cloudflare/stream-react";
import { useNavigate } from "react-router-dom";

export const ChaptersCard = (props) => {
  const playVideo = (data) => {
    window.open(data, "_blank");
  };
  return (
    <>
      <Card
        className="theme-shadow m-2 rounded-3 c-pointer"
        key={props.id}
        onClick={() => playVideo(props.videoUrl)}
      >
        <Card.Body className="d-flex p-2 noselect">
          <div className="video-box">
            <img
              src={props.thumbnail ? props.thumbnail : "/logo192.png"}
              alt="Images"
              className="video-thumbnail"
            ></img>
          </div>
          <div className="d-flex flex-column ms-2">
            <h6 className="font-weight-6x">{props.name}</h6>
            <p className="text-secondary append-ellipsis">
              {props.description}
            </p>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
