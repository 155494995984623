import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./PrivateRoutes";
import AdminRouting from "./AdminRouting";
import AppAccessRouting from "./AppAccessRouting";

const AppRouter = (props) => {
  return (
    <>
      <Routes>
        <Route element={<PrivateRoutes></PrivateRoutes>}>
          <Route exact path="/user/*" element={<AdminRouting />}></Route>
        </Route>
        <Route exact path="/*" element={<AppAccessRouting />}></Route>
      </Routes>
    </>
  );
};

export default AppRouter;
