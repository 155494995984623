import React, { useState, useEffect } from "react";
import { Container, Card, Form } from "react-bootstrap";
import AppAccessLayout from "../../components/appAccessLayout/AppAccessLayout";
import AppAccessBtn from "../../components/appAccessLayout/AppAccessBtn";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../Hooks/api/Api";
import Loader from "../../components/Loader/Loader";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { errorMsg } from "../../Helpers/Error";

const InfoForm = () => {
  const heading = "Perfect!";
  const heading_sub = "Just few more details";
  const formName = "infoForm";
  const btnName = "Start Learning";
  const navigate = useNavigate();
  const userID = JSON.parse(localStorage.getItem("userDetails"))?._id;
  const token = localStorage.getItem("token");
  const initialValues = {
    state: "",
    board: "",
    // school: "",
    class: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [states, setStates] = useState([]);
  const [boards, setBoards] = useState([]);
  const [classes, setClasses] = useState([]);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setFormValues({ ...formValues, [name]: value });
    if (name === "state") {
      getBoards(value);
    }
    if (name === "board") {
      getClasses(value);
    }
  };

  const handleSubmit = (e) => {
    setFormErrors(validate(formValues));
    e.preventDefault();
    setIsSubmit(true);
  };

  // Get All States
  const getStates = () => {
    setLoader(true);
    axiosInstance
      .get("customers/getStates")
      .then((response) => {
        setLoader(false);
        setStates(response.data.data);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  //  Get Boards
  const getBoards = (stateID) => {
    setLoader(true);
    axiosInstance
      .get(`boards/getBoards/${stateID}`)
      .then((response) => {
        setLoader(false);
        setBoards(response.data.data);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  // Get Classes
  const getClasses = (boardID) => {
    setLoader(true);
    axiosInstance
      .get(`classes/getClasses/${boardID}`)
      .then((response) => {
        setLoader(false);
        setClasses(response.data.data);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  useEffect(() => {
    // console.log(formErrors);
    getStates();
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // Submit Form
      setLoader(true);
      // axiosInstance
      //   .post(`customers/update/${userID}`, formValues)
      //   .then((response) => {
      //     setLoader(false);
      //     navigate("/user/home", { replace: true });
      //   })
      //   .catch((error) => {
      //     setLoader(false);
      //     setError(errorMsg(error));
      //     setTimeout(() => {
      //       setError("");
      //     }, 3000);
      //   });
      setLoader(false);
      navigate("/user/home", { replace: true });
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    if (!values.state) {
      errors.state = "State is required";
      document.getElementById("state").classList.add("border-danger");
    }
    if (!values.board) {
      errors.board = "Board is required";
      document.getElementById("board").classList.add("border-danger");
    }
    // if (!values.school) {
    //   errors.school = "School is required";
    //   document.getElementById("school").classList.add("border-danger");
    // }
    if (!values.class) {
      errors.class = "Class is required";
      document.getElementById("class").classList.add("border-danger");
    }
    return errors;
  };

  return (         
    <>
      {loader && <Loader></Loader>}
      <Container fluid className="p-0">
        <Card className="br-bottom">
          <Card.Body className="d-flex align-items-center flex-column">
            <AppAccessLayout
              heading={heading}
              heading_sub={heading_sub}
            ></AppAccessLayout>
            <Form
              noValidate
              id={formName}
              className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4"
              onSubmit={handleSubmit}
            >
              <Form.Select
                aria-label="Select State"
                name="state"
                id="state"
                className="theme-shadow"
                required
                onChange={handleChange}
                value={formValues.state}
              >
                <option value={""}>Select State</option>
                {states &&
                  states.map((state) => (
                    <option key={state._id} value={state._id}>
                      {state.name}
                    </option>
                  ))}
              </Form.Select>
              <small className="text-danger">{formErrors.state}</small>
              <div className="d-flex flex-column mt-1">
                <small className="mb-2">
                  The State you currently reside in
                </small>
              </div>
              <Form.Select
                aria-label="Select Board"
                name="board"
                id="board"
                className="theme-shadow mt-2"
                onChange={handleChange}
                value={formValues.board}
                required
              >
                <option value={""}>Select Board</option>
                {boards.length > 0 &&
                  boards.map((board) => (
                    <option key={board._id} value={board._id}>
                      {board.name}
                    </option>
                  ))}
              </Form.Select>
              <div className="d-flex flex-column mt-1">
                <small>Your School Board (Ex. CBSE, ICSE, etc.)</small>
                <small className="text-danger">{formErrors.board}</small>
                <small className="color-theme-color-1">
                  Can't find your board ?
                </small>
              </div>
              {/* <Form.Select
                aria-label="Select School"
                name="school"
                id="school"
                className="theme-shadow mt-2"
                onChange={handleChange}
                value={formValues.school}
                required
              >
                <option value={""}>Select School</option>
                <option value={"dav"}>DAV</option>
                <option value={"odm"}>ODM</option>
                <option value={"dps"}>DPS</option>
              </Form.Select> */}
              {/* <div className="d-flex flex-column mt-1">
                <small>Your School Name</small>
                <small className="text-danger">{formErrors.school}</small>

                <small className="color-theme-color-1">
                  Can't find your School ?
                </small>
              </div> */}
              <Form.Select
                aria-label="Select Class"
                name="class"
                id="class"
                className="theme-shadow mt-2"
                onChange={handleChange}
                value={formValues.class}
                required
              >
                <option value={""}>Select Class/Grade</option>
                {classes.length > 0 &&
                  classes.map((cls) => (
                    <option key={cls._id} value={cls._id}>
                      {cls.name}
                    </option>
                  ))}
              </Form.Select>
              <div className="d-flex flex-column mt-1">
                <small>Select the class or grade you're in Ex. 11th</small>
                <small className="text-danger">{formErrors.class}</small>
              </div>
              <AppAccessBtn
                formName={formName}
                btnName={btnName}
              ></AppAccessBtn>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      {error && <ErrorToast error={error}></ErrorToast>}
    </>
  );
};

export default InfoForm;
