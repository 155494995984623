import React, { useState } from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import AppRouter from "./Navigate/AppRouter";
import { useSelector, useDispatch } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { SW_INIT, SW_UPDATE } from "./types";
import Alert from "./Alert";
import { Button } from "react-bootstrap";

function App() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const isServiceWorkerInitialized = useSelector(
    (state) => state.serviceWorkerInitialized
  );
  const isServiceWorkerUpdated = useSelector(
    (state) => state.serviceWorkerUpdated
  );

  const handleClick = (type) => {
    if (dispatch({ type })) {
      setShow(false);
    }
  };

  return (
    <>
      <div className="update-header">
        {" "}
        <div className="App-alert">
          {isServiceWorkerInitialized && (
            <Alert text="Application is initialized" type={SW_INIT} />
          )}
          {isServiceWorkerUpdated && (
            <Alert text="Application is updated" type={SW_UPDATE} />
          )}
        </div>
        {isServiceWorkerUpdated && (
          <>
            {show ? (
              <header className="App-header p-3">
                <div className="text-center">
                   <p className="font-size-3x">
                  There is a new version available{" "}
                  
                </p>
                <Button onClick={() => handleClick(SW_UPDATE)}>Update</Button>{" "}
                </div>
               
              </header>
            ) : null}
          </>
        )} 
      </div>

      <div className="App noselect">
        <Routes>
          <Route path="/*" element={<AppRouter />}></Route>
        </Routes>
      </div>
    </>
  );
}

export default App;
