import React, { useState,useEffect } from "react";
import { Card, Container, Image, Form, NavLink } from "react-bootstrap";
import AppAccessLayout from "../../components/appAccessLayout/AppAccessLayout";
import AppAccessBtn from "../../components/appAccessLayout/AppAccessBtn";
import axiosInstance from "../../Hooks/api/Api";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { errorMsg } from "../../Helpers/Error";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import { successMsg } from "../../Helpers/Success";
import SuccessToast from "../../components/SuccessMsg/SuccessToast";

const NumberOtp = () => {
  const mobileNo = localStorage.getItem("mobileNo");
  const navigate = useNavigate();
  const heading = "Enter OTP";
  const heading_sub = "";
  const formName = "otpForm";
  const btnName = "Continue";
  const otpValues = {
    value_1: "",
    value_2: "",
    value_3: "",
    value_4: "",
  };
  const [formValues, setFormValues] = useState(otpValues);
  const [isInvalid, setIsInvalid] = useState(true);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setFormValues({ ...formValues, [name]: value });
    console.log();
  };

  const formValid = () => {
    const formInputs = document.querySelectorAll("input");
    const regex = /^[0-9]*$/;
    const validFormInputs = Array.from(formInputs).filter(
      (input) => input.value && regex.test(input.value)
    );
    if (validFormInputs.length === 4) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setLoader(true);
      const otp =
        formValues.value_1 +
        formValues.value_2 +
        formValues.value_3 +
        formValues.value_4;

      e.preventDefault();
      axiosInstance
        .post(`customers/verifyOtp/${mobileNo}`, { otp: otp })
        .then((response) => {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.data)
          );
          localStorage.setItem("token", response.data.data.token.token);
          setLoader(false);
          setTimeout(() => {
            navigate("/user/home");
            localStorage.setItem("login", true);
            localStorage.removeItem("anotherNo");
          }, 2000);
        })
        .catch((error) => {
          setLoader(false);
          setError(errorMsg(error));
          setTimeout(() => {
            setError("");
          }, 2000);
        });
    }
  };
  const resendOtp = () => {
    setLoader(true);
    axiosInstance
      .post(`customers/resendOtp/${mobileNo}`)
      .then((response) => {
        setLoader(false);
        setSuccess(successMsg(response));
        console.log(success);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 3000);
      });
  };

  const inputFocus = (elem) => {
    if (elem.key === "ArrowLeft" || elem.key === "Backspace") {
      const next = elem.target.tabIndex - 2;
      if (next > -1) {
        elem.target.form.elements[next].focus();
      }
    } else {
      const next = elem.target.tabIndex;
      if (next < 4) {
        elem.target.form.elements[next].focus();
      }
    }
  };

  useEffect(() =>{
    let login = localStorage.getItem('login');
    if (login){
      navigate('/user/home');
    }
  });

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0">
          <Card className="br-top cardheight">
            <Card.Body className="d-flex align-items-center flex-column noselect">
              <AppAccessLayout
                heading={heading}
                heading_sub={heading_sub}
              ></AppAccessLayout>
              <div className="d-flex justify-content-center">
                <Image
                  fluid
                  src={require("../../Assets/images/signIn.png")}
                  className="elem-w-150 m-2"
                ></Image>
              </div>
              <Form
                noValidate
                className="mt-3 gap-2 text-center d-flex justify-content-center px-4"
                id={formName}
                onSubmit={handleSubmit}
                onChange={formValid}
              >
                <Form.Control
                  type="tel"
                  autoComplete="off"
                  name="value_1"
                  id="value_1"
                  value={formValues.value_1}
                  className="mb-2 theme-shadow text-center otpBox border-success bg-theme-gray-14"
                  required
                  onChange={handleChange}
                  tabIndex="1"
                  maxLength="1"
                  onKeyUp={inputFocus}
                ></Form.Control>
                <Form.Control
                  type="tel"
                  autoComplete="off"
                  name="value_2"
                  id="value_2"
                  value={formValues.value_2}
                  className="mb-2 theme-shadow text-center otpBox border-success bg-theme-gray-14   "
                  required
                  onChange={handleChange}
                  tabIndex="2"
                  maxLength="1"
                  onKeyUp={inputFocus}
                ></Form.Control>{" "}
                <Form.Control
                  type="tel"
                  autoComplete="off"
                  name="value_3"
                  id="value_3"
                  value={formValues.value_3}
                  className="mb-2 theme-shadow text-center otpBox border-success bg-theme-gray-14"
                  required
                  onChange={handleChange}
                  tabIndex="3"
                  maxLength="1"
                  onKeyUp={inputFocus}
                ></Form.Control>{" "}
                <Form.Control
                  type="tel"
                  autoComplete="off"
                  name="value_4"
                  id="value_4"
                  value={formValues.value_4}
                  className="mb-2 theme-shadow text-center otpBox border-success bg-theme-gray-14"
                  required
                  onChange={handleChange}
                  tabIndex="4"
                  maxLength="1"
                  onKeyUp={inputFocus}
                ></Form.Control>
              </Form>{" "}
              <Form.Text className="text-secondary text-center color-button-color-1">
                A 4 digit OTP (One Time Password) was sent to your registered
                mobile number. Please enter the same below in order to log in.
              </Form.Text>
              <AppAccessBtn
                formName={formName}
                btnName={btnName}
                disable={isInvalid}
              ></AppAccessBtn>
              <NavLink
                className="fw-bold text-center color-button-color-1"
                onClick={() => navigate("/login")}
              >
                Use a different Number
              </NavLink>
              <small className="text-dark">Didn't receive an OTP ? </small>
              <NavLink
                className="fw-bold text-center color-button-color-1"
                onClick={resendOtp}
              >
                Re-Send OTP
              </NavLink>
            </Card.Body>{" "}
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
        {success && <SuccessToast success={success}></SuccessToast>}
      </div>
    </>
  );
};

export default NumberOtp;
