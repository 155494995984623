import React from "react";
import "./Home.scss";
import { Card } from "react-bootstrap";
import { useNavigate, NavLink } from "react-router-dom";

function HomeStories(props) {
  const navigate = useNavigate();
  const goToCourse = () => {
    navigate("/user/exploreCourses/" + props.courseID);
  };
  return (
    <>
      <Card
        className="elem-max-bgr-w-120 bg-theme-color-2 rounded-1 border-0 theme-shadow"
        key={props.id}
        onClick={goToCourse}
      >
        <Card.Body className="px-2 d-flex justify-content-start align-items-center">
          <div className="d-flex flex-column align-items-start">
            <div className="elem-w-25 elem-h-25 color-theme-color-2 bg-theme-white fw-normal d-flex justify-content-center align-items-center rounded-pill">
              {props.name.charAt(0)}
            </div>
            <div className="text-light append-ellipsis">{props.name}</div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

export default HomeStories;
