import React from "react";
import { Stream } from "@cloudflare/stream-react";

const StreamVideo = () => {
  return (
    <>
      <div>
        <Stream controls={true} />
      </div>
      ;
    </>
  );
};

export default StreamVideo;
