import React from "react";
import { GoThreeBars } from "react-icons/go";
import SideBar from "./SideBar";
import Overlay from "./Overlay";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";

const NavApp = (props) => {
  const sideBar = "sideBar";
  const overlay = "overlay";
  const navigate = useNavigate();
  const toggleSwitch = () => {
    const sideBar = document.getElementById("sideBar");
    const overlay = document.getElementById("overlay");
    sideBar.style.width = "270px";
    overlay.style.width = "100%";
  };
  const back = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="d-flex align-items-center cons stickyNav-top bg-theme-color-1 p-2">
        {props.showBars && (
          <GoThreeBars
            className="text-light font-size-2x c-pointer"
            onClick={toggleSwitch}
          />
        )}
        {!props.showBars && (
          <FaChevronLeft
            className="text-light font-size-2x c-pointer"
            onClick={back}
          />
        )}
        <h4 className="text-light m-0 ps-4" id="userName">
          {props.title}
        </h4>
      </div>
      <SideBar id={sideBar}></SideBar>
      <Overlay id={overlay}></Overlay>
    </>
  );
};

export default NavApp;
