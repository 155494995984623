import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";
import { Form } from "react-bootstrap";
import AppAccessBtn from "../../components/appAccessLayout/AppAccessBtn";
import Loader from "../../components/Loader/Loader";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import axiosInstance from "../../Hooks/api/Api";
import { useParams, useNavigate } from "react-router-dom";
import { errorMsg } from "../../Helpers/Error";
import { successMsg } from "../../Helpers/Success";
import SuccessToast from "../../components/SuccessMsg/SuccessToast";

const UpdatePassword = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const heading = "Update Your Password";
  const heading_sub = "You can change your password for better security.";
  const formName = "updatePassword";
  const btnName = "Update password";
  const [formValues, setFormValues] = useState({
    currentPassword: "",
    password: "",
    cpassword: "",
  });
  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isInvalid, setIsInvalid] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);
  const [success, setSuccess] = useState("");

  const handleChange = (e) => {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    setFormValues({ ...formValues, [name]: value });
  };

  const formValid = () => {
    const formInputs = document.querySelectorAll("input");
    const validFormInputs = Array.from(formInputs).filter(
      (input) => input.value
    );
    if (validFormInputs.length === 3) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.currentPassword) {
      errors.currentPassword = "Current password is required";
      document.getElementById("currentPassword").classList.add("border-danger");
    } else {
      document
        .getElementById("currentPassword")
        .classList.remove("border-danger");
    }
    if (!values.password) {
      errors.password = "New password is required";
      document.getElementById("password").classList.add("border-danger");
    } else {
      document.getElementById("password").classList.remove("border-danger");
    }
    if (!values.cpassword) {
      errors.cpassword = "Confirm password is required";
      document.getElementById("cpassword").classList.add("border-danger");
    } else {
      document.getElementById("cpassword").classList.remove("border-danger");
    }
    if (values.password.localeCompare(values.cpassword) !== 0) {
      errors.cpassword = "Confirm Password and Password Did not match!!";
      document.getElementById("cpassword").classList.add("border-danger");
    }

    return errors;
  };

  const handleSubmit = (e) => {
    setFormErrors(validate(formValues));
    e.preventDefault();
    setIsSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      const reqData = {
        currentPassword: formValues.currentPassword,
        password: formValues.password,
      };
      setLoader(true);
      console.log(reqData);
      axiosInstance
        .post(`customers/updatePassword/${id}`, reqData)
        .then((response) => {
          setSuccess(successMsg(response));
          setLoader(false);
          setTimeout(() => {
            setSuccess("");
            navigate("/user/profile");
          }, 2000);
        })
        .catch((error) => {
          setLoader(false);
          setError(errorMsg(error));
          setTimeout(() => {
            setError("");
          }, 3000);
        });
    }
  }, [formErrors]);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0">
          <Card className="br-top" style={{ height: "85vh" }}>
            <Card.Body className="d-flex align-items-center flex-column">
              <Form
                id={formName}
                className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4"
                onChange={formValid}
                onSubmit={handleSubmit}
              >
                <FormAccessLayout
                  heading={heading}
                  heading_sub={heading_sub}
                ></FormAccessLayout>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  name="currentPassword"
                  id="currentPassword"
                  placeholder="Current Password"
                  className="mb-2 theme-shadow text-left"
                  value={formValues.currentPassword}
                  onChange={handleChange}
                  required
                ></Form.Control>
                <small className="text-danger">
                  {formErrors.currentPassword}
                </small>
                <Form.Control
                  type="password"
                  autoComplete="off"
                  name="password"
                  id="password"
                  placeholder="New Password"
                  className="mb-2 theme-shadow text-left"
                  value={formValues.password}
                  onChange={handleChange}
                  required
                ></Form.Control>
                <small className="text-danger">{formErrors.password}</small>

                <Form.Control
                  type="password"
                  autoComplete="off"
                  name="cpassword"
                  id="cpassword"
                  placeholder="Confirm Password"
                  className="mb-2 theme-shadow text-left"
                  value={formValues.cpassword}
                  onChange={handleChange}
                  required
                ></Form.Control>
                <small className="text-danger">{formErrors.cpassword}</small>

                <AppAccessBtn
                  formName={formName}
                  btnName={btnName}
                  disable={isInvalid}
                ></AppAccessBtn>
              </Form>
            </Card.Body>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
        {success && <SuccessToast success={success}></SuccessToast>}
      </div>
    </>
  );
};

export default UpdatePassword;
