import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";

import { AiOutlineRight } from "react-icons/ai";
import { BsHeart, BsStar, BsStarFill, BsHeartFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import axiosInstance from "../../Hooks/api/Api";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { errorMsg } from "../../Helpers/Error";

const VideoCourse = (props) => {
  const pageName = "Course";
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const [error, setErrors] = useState("");
  const [course, setCourseDetails] = useState({});
  const [selectedindex, setSelectedIndex] = useState("1");
  const [rate, setRate] = useState(0);
  const [like, setLike] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const [chapters, setChapters] = useState([]);

  const courseDetails = () => {
    setLoader(true);
    axiosInstance
      .get(`courses/getCourseDetails/${id}`)
      .then((data) => {
        setLoader(false);
        setCourseDetails(data.data.data);
        setChapters(
          data.data.data.chapters.sort(function (a, b) {
            return sortArray(a.chapterNo, b.chapterNo);
          })
        );
        const reqData = data.data.data;
        setSelectedIndex(
          reqData["currentChapter"] && reqData["currentChapter"]["chapterNo"]
            ? reqData["currentChapter"]["chapterNo"]
            : "1"
        );
      })
      .catch((error) => {
        setLoader(false);
        setErrors(errorMsg(error));
        setTimeout(() => {
          setErrors("");
        }, 2000);
      });
  };

  const selectedCourse = (index) => {
    setSelectedIndex(index);
  };

  const rateCourse = (courseID) => {
    setLoader(true);
    rate === 0 ? setRate(1) : setRate(0);
    axiosInstance
      .post(`courses/rate/${courseID}`, { rating: rate })
      .then((response) => {
        setLoader(false);
        courseDetails();
      })
      .catch((error) => {
        setLoader(false);
        setErrors(errorMsg(error));
        setTimeout(() => {
          setErrors("");
        }, 2000);
      });
  };

  const likeCourse = (courseID) => {
    setLoader(true);
    like === false ? setLike(true) : setLike(false);
    axiosInstance
      .post(`courses/like/${courseID}`, { like: like })
      .then((response) => {
        setLoader(false);
        console.log(response.data);
        courseDetails();
      })
      .catch((error) => {
        setLoader(false);
        setErrors(errorMsg(error));
        setTimeout(() => {
          setErrors("");
        }, 2000);
      });
  };

  const subscribeCourse = (courseID) => {
    setLoader(true);
    subscribe === false ? setSubscribe(true) : setSubscribe(false);
    axiosInstance
      .post(`courses/subscribe/${courseID}`, { subscribe: subscribe })
      .then((response) => {
        setLoader(false);
        console.log(response);
        courseDetails();
      })
      .catch((error) => {
        setLoader(false);
        setErrors(errorMsg(error));
        setTimeout(() => {
          setErrors("");
        }, 2000);
      });
  };

  const sortArray = (a, b) => {
    a = parseInt(a);
    b = parseInt(b);
    return a < b ? -1 : a > b ? 1 : 0;
  };

  const videoPlay = (videoData, btnClicked) => {
    videoData["btnType"] = btnClicked;
    navigate("/user/playVideo", { state: videoData });
  };

  useEffect(() => {
    courseDetails();
  }, []);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <HeaderApp pageName={pageName}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="min-vh-100 course-holder position-relative">
            <Card.Img
              className="course-image-holder"
              src={
                course["media"]
                  ? course["media"]
                  : require("../../Assets/images/logo.png")
              }
            ></Card.Img>
            <div className="bg-dark text-light p-2">
              <Card.Title>{course["name"] ? course["name"] : "NA"}</Card.Title>
              <Card.Text>
                {course["levels"] &&
                course["levels"][0] &&
                course["levels"][0]["level"]
                  ? course["levels"][0]["level"]["name"]
                  : "NA"}
                {" | "}
                {course["category"] && course["category"]["name"]
                  ? course["category"]["name"]
                  : "NA"}
                {" | "}
                {course["topic"] && course["topic"]["name"]
                  ? course["topic"]["name"]
                  : "NA"}
              </Card.Text>
            </div>
            <div className="small mt-2 text-secondary px-2">
              {course["description"] ? course["description"] : "NA"}
            </div>
            <div className="d-flex justify-content-between align-items-baseline">
              <div className="d-flex flex-wrap px-2 gap-2">
                <button
                  className="btn small text-light btn-sm rounded-2 btn-button-color-1"
                  onClick={() => videoPlay(course["chapters"][0], "start")}
                >
                  Start Course
                  <span>
                    <AiOutlineRight className="text-light"></AiOutlineRight>
                  </span>
                </button>
                <button
                  className="btn small text-light btn-sm rounded-2 btn-button-color-1"
                  onClick={() => subscribeCourse(course["_id"])}
                >
                  {course["courseCustomer"] &&
                  course["courseCustomer"]["subscribe"]
                    ? "Subscribed"
                    : "Subscribe"}
                </button>
                {course["currentChapter"] && course["currentChapter"]["name"] && (
                  <button
                    className=" btn text-light btn-sm btn-theme-gray-3 rounded-2 small"
                    onClick={() => {
                      videoPlay(course["currentChapter"], "resume");
                    }}
                  >
                    Resume Course
                    <span>
                      <AiOutlineRight></AiOutlineRight>{" "}
                    </span>
                  </button>
                )}
              </div>
              <div className="px-2 d-flex gap-2">
                {course["courseCustomer"] &&
                course["courseCustomer"]["like"] ? (
                  <BsHeartFill
                    className="color-button-color-1"
                    onClick={() => likeCourse(course["_id"])}
                  ></BsHeartFill>
                ) : (
                  <BsHeart
                    className="color-button-color-1"
                    onClick={() => likeCourse(course["_id"])}
                  ></BsHeart>
                )}
                {course["courseCustomer"] &&
                course["courseCustomer"]["rating"] ? (
                  <BsStarFill
                    className="color-button-color-1"
                    onClick={() => rateCourse(course["_id"])}
                  ></BsStarFill>
                ) : (
                  <BsStar
                    className="color-button-color-1"
                    onClick={() => rateCourse(course["_id"])}
                  ></BsStar>
                )}
              </div>
            </div>
            <div className="px-2 mt-2">
              <h5 className="color-theme-color-3 fw-bold">Chapters</h5>
            </div>
            {chapters.length > 0 &&
              chapters.map((chapter, index) => (
                <div
                  className="d-flex justify-content-start mb-2 gap-3 align-items-center px-3 c-pointer"
                  key={chapter["_id"]}
                  onClick={() => selectedCourse(chapter["chapterNo"])}
                >
                  {chapter["chapterNo"].length === 1 && (
                    <h2
                      className={
                        chapter["chapterNo"] === selectedindex
                          ? "color-button-color-1 fw-bold font-size-8x"
                          : "color-theme-gray-13 fw-bold font-size-8x"
                      }
                    >
                      {"0"}
                      {chapter["chapterNo"]}
                    </h2>
                  )}
                  {chapter["chapterNo"].length > 1 && (
                    <h2
                      className={
                        chapter["chapterNo"] === selectedindex
                          ? "color-button-color-1 fw-bold font-size-8x"
                          : "color-theme-gray-13 fw-bold font-size-8x"
                      }
                    >
                      {chapter["chapterNo"]}
                    </h2>
                  )}
                  <div className="flex-grow-1">
                    <small className="fw-bold text-break color-button-color-1">
                      {chapter["name"] ? chapter["name"] : "NA"}
                    </small>
                    <small className="text-secondary append-ellipsis">
                      {chapter["description"] ? chapter["description"] : "NA"}
                    </small>
                  </div>
                  <button
                    className="btn btn-sm rounded-1 btn-button-color-1 text-light"
                    onClick={() => videoPlay(chapter, "playfrom")}
                  >
                    <AiOutlineRight className="fs-6"></AiOutlineRight>
                  </button>
                </div>
              ))}
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default VideoCourse;
