import React, { useState, useEffect } from "react";

import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";

import { Card, Container, Button } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Hooks/api/Api";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../components/Loader/Loader";
import { dateFormat } from "../../Helpers/DateFormat";
import { successMsg } from "../../Helpers/Success";
import { errorMsg } from "../../Helpers/Error";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import SuccessToast from "../../components/SuccessMsg/SuccessToast";

const ParentsStudentInfo = () => {
  const navigate = useNavigate();
  const currentData = JSON.parse(localStorage.getItem("currentUserData"));

  const initialValues = {
    name: currentData.name,
    relationship: currentData.parent.relationship,
    gender: currentData.gender,
    dob: dateFormat(currentData.dob, "yyyy-MM-dd"),
  };
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const parentDOB = userData.parent.dob;
  const parentName = userData.parent.name;

  const heading = "Student' Details";
  const heading_sub = "Basic Details about the student";
  let { id } = useParams();

  const relations = [
    { name: "Father", id: "father" },
    { name: "Mother", id: "mother" },
    { name: "Brother", id: "brother" },
    { name: "Sister", id: "sister" },
    { name: "Uncle", id: "uncle" },
    { name: "Aunt", id: "aunt" },
    { name: "Husband", id: "husband" },
    { name: "Wife", id: "wife" },
    { name: "Guardian", id: "guardian" },
  ];

  const profileValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    relationship: Yup.string().required("Relationship is required"),
    gender: Yup.string().required("Gender is required"),
    dob: Yup.string().required("Date Of Birth is required"),
  });

  const handleSubmit = (data) => {
    const reqData = {
      name: data.name,
      dob: data.dob,
      gender: data.gender,
      parent: {
        name: parentName,
        dob: parentDOB,
        relationship: data.relationship,
      },
    };
    setLoader(true);
    axiosInstance
      .post(`/customers/update/${id}`, reqData)
      .then((response) => {
        setSuccess(successMsg(response));
        setTimeout(() => {
          setLoader(false);
          setSuccess("");
          navigate("/user/profile");
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        });
      });
  };

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0">
          <Card className="br-top" style={{ height: "85vh" }}>
            <Card.Body className="d-flex align-items-center flex-column">
              <Formik
                initialValues={initialValues}
                validationSchema={profileValidationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
                enableReinitialize={true}
              >
                <Form
                  className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4 d-flex flex-column gap-2"
                  noValidate
                >
                  <FormAccessLayout
                    heading={heading}
                    heading_sub={heading_sub}
                  ></FormAccessLayout>
                  <Field
                    id="name"
                    type="text"
                    name="name"
                    className="form-control theme-shadow"
                    required
                  ></Field>

                  <ErrorMessage
                    component="div"
                    name="name"
                    className="error text-danger"
                  />

                  <Field
                    as="select"
                    name="relationship"
                    id="relationship"
                    className="form-select theme-shadow"
                  >
                    <option value={""} disabled>
                      {"Relationship with student"}
                    </option>
                    {relations.map((relation) => (
                      <option key={relation.id} value={relation.id}>
                        {relation.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="relationship"
                    className="error text-danger"
                  />
                  <Field
                    type="date"
                    autoComplete="off"
                    name="dob"
                    id="dob"
                    className="form-control theme-shadow"
                  ></Field>
                  <ErrorMessage
                    component="div"
                    name="dob"
                    className="error text-danger"
                  />
                  <Field
                    as="select"
                    className="form-select theme-shadow"
                    name="gender"
                    id="gender"
                  >
                    <option value={""} disabled>
                      Gender *
                    </option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                    <option value={"other"}>Other</option>
                  </Field>
                  <ErrorMessage
                    component="div"
                    name="gender"
                    className="error text-danger"
                  />
                  <Button
                    type="submit"
                    className="border-0  mt-2 col-12 theme-shadow "
                    variant="button-color-1 color-theme-white"
                  >
                    Update
                  </Button>
                </Form>
              </Formik>
            </Card.Body>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
        {success && <SuccessToast success={success}></SuccessToast>}
      </div>
    </>
  );
};

export default ParentsStudentInfo;
