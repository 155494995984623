import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Card, Container } from "react-bootstrap";
import { Image } from "react-bootstrap";

import AppAccessLayout from "../../components/appAccessLayout/AppAccessLayout";
import AppAccessBtn from "../../components/appAccessLayout/AppAccessBtn";
import { useNavigate, NavLink } from "react-router-dom";
import axiosInstance from "../../Hooks/api/Api";
import Loader from "../../components/Loader/Loader";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { errorMsg } from "../../Helpers/Error";

const Loginform = () => {
  const heading = "Lets Begin";
  const heading_sub = "Verfiy Mobile Number";
  const btnName = "Verify Using OTP";
  const formName = "loginForm";
  const errMsg = "Invalid Mobile Number. Please Check";
  const navigate = useNavigate();

  const currentNo = JSON.parse(localStorage.getItem("anotherNo"));
  const initialValues = {
    mobileNo: currentNo,
  };
  const [value, setValue] = useState(initialValues);
  const [validated, setValidated] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const { mobileNo } = value;
      setLoader(true);
      axiosInstance
        .get(`customers/checkCustomer/${mobileNo}`)
        .then((response) => {
          localStorage.setItem("mobileNo", mobileNo);
          if (response.data.data) {
            axiosInstance
              .post(`customers/sendOtp/${mobileNo}`, {})
              .then((data) => {
                setLoader(false);
                navigate("/numberOtp", { repalce: true });
              })
              .catch((error) => {
                setLoader(false);
                setError(errorMsg(error));
                setTimeout(() => {
                  setError("");
                }, 3000);
              });
          } else {
            setError(
              "Seems you don't have an account ? Please register to proceed"
            );
            setLoader(false);
            setTimeout(() => {
              setError("");
            }, 3000);
          }
        })
        .catch((error) => {
          setLoader(false);
          setError(errorMsg(error));
          setTimeout(() => {
            setError("");
          }, 3000);
        });
    }
    setValidated(true);
  };

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });

    localStorage.setItem("anotherNo", JSON.stringify(e.target.value));
    if (e.target.value.length === 10) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  };

  useEffect(() =>{
    let login = localStorage.getItem('login');
    if (login){
      navigate('/user/home');
    }
  });

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0">
          <Card className="br-top mt-5 pt-2 cardheight ">
            <Card.Body className="d-flex align-items-center flex-column noselect">
              <AppAccessLayout
                heading={heading}
                heading_sub={heading_sub}
              ></AppAccessLayout>
              <div className="d-flex justify-content-center my-3">
                <Image
                  fluid
                  src={require("../../Assets/images/signIn.png")}
                  className="elem-w-200"
                ></Image>
              </div>
              <Form
                noValidate
                className="col-12 col-md-6 col-xl-6 text-center"
                id={formName}
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Form.Group className="" controlId="mobileNo">
                  <Form.Control
                    name="mobileNo"
                    placeholder="Mobile Number"
                    required
                    autoComplete="off"
                    type="tel"
                    value={value.mobileNo ?? ""}
                    className="text-left mb-3  theme-shadow"
                    pattern="^[0-9]{10}$"
                    onChange={handleChange}
                    maxLength="10"
                  />
                  <Form.Text className="row px-2 justify-content-center color-button-color-1">
                    Enter 10 digit mobile number without country code (+91){" "}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid" className="errorMsg">
                    {errMsg}
                  </Form.Control.Feedback>
                </Form.Group>
                <AppAccessBtn
                  formName={formName}
                  btnName={btnName}
                  disable={!validated}
                ></AppAccessBtn>
              </Form>

              <div className="d-flex flex-column align-items-center">
                <div className="color-theme-color-1 m-0 fw-bold">Or</div>
                <NavLink
                  className="text-decoration-none small text-dark"
                  to={"/login-password"}
                >
                  Login Using Password
                </NavLink>
              </div>
            </Card.Body>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default Loginform;
