import { Card } from "react-bootstrap";

const ErrorToast = (props) => {
  return (
    <>
      <div className="d-flex position-absolute bottom-0 mb-3 justify-content-center container-fluid layer-top">
        <Card className="bg-theme-gray-14 py-1 px-2 br-4">
          <div className="text-danger font-size-2x text-center font-weight-5x">
            {props.error}
          </div>
        </Card>
      </div>
    </>
  );
};

export default ErrorToast;
