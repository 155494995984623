// import { Outlet, Navigate } from "react-router-dom";

// const useAuth = () => {
//   const user = { token: localStorage.getItem("token") };
//   return user && user.loggedIn;
// };

// const PrivateRoutes = () => {
//   let isAuth = useAuth();
//   return isAuth ? <Outlet /> : <Navigate to="/signup" />;
// };



// export default PrivateRoutes;



import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {
  let token = { token: localStorage.getItem("token") };
  return token.token ? <Outlet /> : <Navigate to="/signup" />;
};



export default PrivateRoutes;
