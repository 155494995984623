import { IoIosArrowBack } from "react-icons/io";
import React, { useEffect, useState } from "react";
import { Card, Container, Form } from "react-bootstrap";

import ErrorToast from "../../components/errorMsg/ErrorToast";
import Loader from "../../components/Loader/Loader";
import FooterApp from "../../components/appAdminLayout/FooterApp";
import { useNavigate, useParams } from "react-router-dom";
import { ImEqualizer } from "react-icons/im";
import { GrClose } from "react-icons/gr";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import axiosInstance from "../../Hooks/api/Api";
import { errorMsg } from "../../Helpers/Error";
import CardList from "./CardList";

const DetailsCard = (props) => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [courses, setCourses] = useState([]);
  const [searchData, setSearchData] = useState({});
  const [selectedSubLevel, setSelectedSubLevel] = useState([]);
  const [filter, setFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedFilterSubLevel, setSelectedFilterSubLevel] = useState("");
  const [sort, setSort] = useState(-1);
  const [show, setShow] = useState(false);
  const savedTopicName = localStorage.getItem("topicName")
    ? localStorage.getItem("topicName")
    : "NA";
  const [topicName, setTopicName] = useState();
  const pageName = "Explore";
  let { tid } = useParams();

  const getCourses = (search) => {
    setLoader(true);
    axiosInstance
      .post("courses/listTopicCourses/" + tid, search)
      .then((data) => {
        setLoader(false);
        setCourses(
          data.data.data.sort(function (a, b) {
            return sortArray(b.name, a.name);
          })
        );
        setTopicName(
          data.data.data.length > 0 &&
            data.data.data[0].topic &&
            data.data.data[0].topic.name
            ? data.data.data[0].topic.name
            : savedTopicName
        );
        sortData();
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const getCoursesOptions = (search) => {
    setLoader(true);
    axiosInstance
      .post(`levels/listLevel`, search)
      .then((response) => {
        setLoader(false);
        setFilterOptions(response.data.data);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const filterSubLevel = (e) => {
    setSelectedLevel(e);
    const levelID = e;
    filterOptions.forEach((level) => {
      if (levelID === level["_id"]) {
        setSelectedSubLevel(level["subLevels"]);
      }
    });
  };

  const subLevel = (e) => {
    setSelectedFilterSubLevel(e.target.value);
  };

  const openFilter = () => {
    getCoursesOptions({});
    document.getElementById("courseTypes").classList.add("open");
  };
  const closeFilter = () => {
    document.getElementById("courseTypes").classList.remove("open");
  };

  const clearData = () => {
    closeFilter();
    setSearchData({});

    document.getElementById("create-course-form").reset();
  };

  const handleFilter = () => {
    setSearchData({ level: selectedLevel, subLevel: selectedFilterSubLevel });
    document.getElementById("courseTypes").classList.remove("open");
    setFilter(true);
  };

  const sortData = () => {
    sort === -1 ? setSort(1) : setSort(-1);
    if (sort === -1) {
      courses.sort(function (a, b) {
        return sortArray(b.name, a.name);
      });
    }
    if (sort === 1) {
      courses.sort(function (a, b) {
        return sortArray(a.name, b.name);
      });
    }
  };

  const sortArray = (a, b) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  };

  useEffect(() => {
    getCourses(searchData);
  }, [searchData]);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}

        <Card className="types-filter br-top" id="courseTypes">
          <div className="text-end c-pointer p-3" onClick={closeFilter}>
            <GrClose className="color-button-color-1"></GrClose>
          </div>
          <div className="d-flex flex-column align-items-start p-3">
            <h5 className="text-dark text-capitalize mb-0 fw-bold">
              Education Level
            </h5>
            <small className="text-secondary text-capitalize">
              Your current educational background
            </small>
            <div className="mt-3 w-100">
              <Form id="create-course-form">
                <Form.Select
                  defaultValue={""}
                  onChange={(e) => {
                    filterSubLevel(e.target.value);
                  }}
                  className="mb-2 theme-shadow text-left"
                  id="level"
                  name="level"
                  onClick={() => setShow(true)}
                >
                  <option value={""}> You're Currently?</option>
                  {filterOptions.map((levelName, index) => (
                    <option
                      value={levelName["_id"]}
                      key={levelName["_id"]}
                      name={levelName["_id"]}
                      id={index}
                    >
                      {levelName.name}
                    </option>
                  ))}
                </Form.Select>
                {show ? (
                  <Form.Select
                    defaultValue={""}
                    onChange={subLevel}
                    className="mb-2 theme-shadow text-left"
                    id="subLevel"
                    name="subLevel"
                  >
                    <option value={""}>Class,Grade,Year</option>
                    {selectedSubLevel.map((subLevel) => (
                      <option value={subLevel["_id"]} key={subLevel["_id"]}>
                        {subLevel["name"]}
                      </option>
                    ))}
                  </Form.Select>
                ) : null}
              </Form>
            </div>
          </div>
          <div className="row w-100 position-absolute bottom-0 mx-0 p-0  border-button-color-1 text-uppercase align-items-center">
            <div
              className="col-6 color-button-color-1 text-center small elem-h-30 py-1 c-pointer"
              onClick={clearData}
              id="clearFilter"
            >
              clear
            </div>
            <div
              className="col-6 bg-button-color-1 text-light text-center small py-1 elem-h-30 c-pointer"
              onClick={handleFilter}
            >
              apply
            </div>
          </div>
        </Card>

        <HeaderApp pageName={pageName}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-start align-items-center">
                <IoIosArrowBack
                  className="square-25 text-white bg-button-color-1 rounded-1 me-2"
                  onClick={() => navigate(-1)}
                ></IoIosArrowBack>
                <h6 className="text-capitalize color-button-color-1 mb-0 fw-bold">
                  {topicName}
                </h6>
              </div>
              <div className="d-flex justify-content-center align-items-baseline gap-1">
                <div className="c-pointer" onClick={openFilter} id="filterBtn">
                  <ImEqualizer color="gray" />
                </div>
                <div
                  className="fw-bold small color-button-color-1 c-pointer"
                  onClick={sortData}
                >
                  {sort === -1 ? "AZ" : "ZA"}
                </div>
              </div>
            </div>
            <div className="mt-3 d-flex flex-wrap justify-content-start pb-5">
              {courses.map((course) => (
                <CardList
                  courseID={course._id}
                  key={course._id}
                  courseName={course.name}
                  categoryName={course.category.name}
                  topicName={course.topic.name}
                  courseImage={course.media}
                ></CardList>
              ))}
            </div>
          </Card>
        </Container>
        <FooterApp></FooterApp>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default DetailsCard;
