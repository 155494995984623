import React from "react";
import { Container } from "react-bootstrap";

const Splash = () => {
  return (
    <>
      <Container fluid className="p-0 position-absolute bottom-0">
        <div className="splash-screen min-vh-100"></div>
      </Container>
    </>
  );
};

export default Splash;
