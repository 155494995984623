import React, { useEffect } from "react";
import { Card, Container, Button } from "react-bootstrap";
import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";

import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import Loader from "../../components/Loader/Loader";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { Formik, Form, Field } from "formik";

import * as Yup from "yup";

const StudentPage = (props) => {
  const heading = "Let's get to know you";
  const heading_sub = "Step 1 - Few personal details about you";

  const navigate = useNavigate();

  const StudentSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too  Long!")
      .required("This is Required"),

    dob: Yup.string().required("Date Of Birth Is Required"),
    phoneNo: Yup.string()
      .required("Phone number is required")

      .matches(/^[0-9]{10}$/g, "Invalid phone number"),
    gender: Yup.string().required("This is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password is too short - should be 6 chars minimum"),

    cpassword: Yup.string()
      .required("Confirm Password is Required")
      .min(6, "Password is too short - should be 6 chars minimum"),
  });

  const changeType = () => {
    const elementID = document.getElementById("dob");

    elementID.type = elementID.type === "text" ? "date" : "text";
  };

  const handleSubmit = (values) => {
    props.next(values, true);
  };
  useEffect(() =>{
    let login = localStorage.getItem('login');
    if (login){
      navigate('/user/home');
    }
  });

  return (
    <>
      <div className="main-wrapper">
        <Container fluid className="p-0 position-absolute bottom-0 ">
          <Card className="br-top  cardheight">
            <Card.Body className="d-flex align-items-center flex-column">
              <Formik
                initialValues={props.data}
                onSubmit={handleSubmit}
                validationSchema={StudentSchema}
              >
                {({ errors, touched, isValidating }) => (
                  <Form
                    className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4 d-flex flex-column gap-2"
                    noValidate
                  >
                    <div className="d-flex justify-content-start ">
                      <AiOutlineLeft
                        className="color-button-color-1 font-size-8x"
                        onClick={() => navigate("/signup")}
                      />
                      <FormAccessLayout
                        heading={heading}
                        heading_sub={heading_sub}
                      ></FormAccessLayout>
                    </div>
                    <Field
                      type="text"
                      autoComplete="off"
                      name="name"
                      id="name"
                      placeholder="Your Name *"
                      className="form-control theme-shadow text-left"
                      required
                    />
                    {errors.name && touched.name && (
                      <span className="error text-danger">{errors.name}</span>
                    )}

                    <Field
                      type="email"
                      autoComplete="off"
                      name="email"
                      id="email"
                      placeholder="Email Address"
                      className="form-control theme-shadow text-left"
                      required
                    />

                    <Field
                      type="tel"
                      autoComplete="off"
                      name="phoneNo"
                      id="phoneNo"
                      placeholder="Mobile Number *"
                      className="form-control theme-shadow text-left"
                      required
                    />
                    {errors.phoneNo && touched.phoneNo && (
                      <span className="error text-danger">
                        {errors.phoneNo}
                      </span>
                    )}

                    <Field
                      as="select"
                      className="form-control theme-shadow text-left "
                      name="gender"
                      id="gender"
                    
                    >
                      <option value={""} disabled>
                        Gender *
                      </option>
                      <option value={"male"}>Male</option>
                      <option value={"female"}>Female</option>
                      <option value={"other"}>Other</option>
                    </Field>
                    {errors.gender && touched.gender && (
                      <span className="error text-danger">{errors.gender}</span>
                    )}

                    <Field
                      type="text"
                      onFocus={changeType}
                      onBlur={changeType}
                      autoComplete="off"
                      name="dob"
                      id="dob"
                      className="form-control mb-0 theme-shadow text-left"
                      placeholder="Date of Birth *"
                    />

                    {errors.dob && touched.dob && (
                      <span className="error text-danger">{errors.dob}</span>
                    )}

                    <Field
                      type="password"
                      autoComplete="off"
                      name="password"
                      id="password"
                      placeholder="password *"
                      className="form-control theme-shadow text-left"
                      required
                    />
                    {errors.password && touched.password && (
                      <span className="error text-danger">
                        {errors.password}
                      </span>
                    )}

                    <Field
                      type="password"
                      autoComplete="off"
                      name="cpassword"
                      id="cpassword"
                      placeholder="Confirm Password *"
                      className="form-control theme-shadow text-left"
                      required
                    />
                    {errors.cpassword && touched.cpassword && (
                      <span className="error text-danger">
                        {errors.cpassword}
                      </span>
                    )}

                    <Button
                      type="submit"
                      className="border-0 mt-2 col-12 theme-shadow "
                      variant="button-color-1 color-theme-white"
                    >
                      Continue
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default StudentPage;
