import React, { useState, useEffect } from "react";
import { Card, Container, Button } from "react-bootstrap";
import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";
import axiosInstance from "../../Hooks/api/Api";
import Loader from "../../components/Loader/Loader";
import { AiOutlineLeft } from "react-icons/ai";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as Yup from "yup";

const EducationLevel = (props) => {
  const heading = "Education Level";
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [eduLevel, setEduLevel] = useState([]);
  const [selectedSubLevel, setSelectedSubLevel] = useState([]);
  const [page, setPage] = useState(2);
  const [show, setShow] = useState(false);
  const [pageTitle, setPageTitle] = useState(
    "Your current educational background"
  );

  const educationLevel = () => {
    setLoader(true);
    axiosInstance
      .post("levels/listLevel", {})

      .then((result) => {
        setEduLevel(result.data.data);
        setLoader(false);

        if (props.data && props.data.level) {
          const levelID = props.data.level;
          result.data.data.forEach((level) => {
            if (levelID === level["_id"]) {
              setSelectedSubLevel(level["subLevels"]);
              setShow(true);
            }
          });
        }
      })
      .catch((error) => {
        if (error.result) {
          console.log(error.result);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  const filterSubLevel = (e) => {
    const levelID = e;
    eduLevel.forEach((level) => {
      if (levelID === level["_id"]) {
        setSelectedSubLevel(level["subLevels"]);
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem("type") === "parent") {
      setPage(3);
      setPageTitle(`Details of your student's education`);
    }
    educationLevel();
  }, []);

  const EducationSchema = Yup.object().shape({
    level: Yup.string().required("Level Is Required"),
  });

  const handleSubmit = (values) => {
    props.next(values, true);
  };

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0">
          <Card className="br-top cardheight">
            <Card.Body className="d-flex align-items-center flex-column">
              <Formik
                initialValues={props.data}
                onSubmit={handleSubmit}
                validationSchema={EducationSchema}
              >
                {(values) => (
                  <Form
                    className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4 d-flex flex-column gap-2"
                    noValidate
                  >
                    <div className="d-flex justify-content-start ">
                      <AiOutlineLeft
                        className="color-button-color-1 font-size-8x c-pointer"
                        onClick={() => props.prev(values)}
                      />
                      <FormAccessLayout
                        heading={heading}
                        heading_sub={`Step ${page}- ${pageTitle} `}
                      ></FormAccessLayout>
                    </div>

                    <Field
                      name="level"
                      as="select"
                      onChange={(e) => {
                        values.handleChange(e);
                        filterSubLevel(e.currentTarget.value);
                      }}
                      onClick={() => setShow(true)}
                      className="form-select theme-shadow"
                    >
                      <option value={""}>
                        {localStorage.getItem("type") === "parent"
                          ? "My student is currently in *"
                          : "You're Currently? *"}{" "}
                      </option>
                      {eduLevel.map((listName) => (
                        <option
                          value={listName._id}
                          name={listName._id}
                          key={listName._id}
                          id="level"
                        >
                          {listName.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="level"
                      className="error text-danger"
                    />

                    {show ? (
                      <Field
                        name="subLevel"
                        as="select"
                        className="form-select theme-shadow"
                        onChange={(e) => {
                          values.handleChange(e);
                        }}
                      >
                        <option value={""}>Class, Grade, Year</option>
                        {selectedSubLevel.map((subLevel) => (
                          <option value={subLevel["_id"]} key={subLevel["_id"]}>
                            {subLevel["name"]}
                          </option>
                        ))}
                      </Field>
                    ) : null}

                    <Field
                      type="text"
                      autoComplete="off"
                      name="board"
                      id="board"
                      className="form-control mb-2 theme-shadow text-left"
                      placeholder="Board or University"
                    />

                    <Button
                      type="submit"
                      className="border-0  mt-2 col-12 theme-shadow "
                      variant="button-color-1 color-theme-white"
                    >
                      Continue
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default EducationLevel;
