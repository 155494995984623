import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HomeCard = (props) => {
  const navigate = useNavigate();
  const goToCourse = (id) => {
    navigate(`/user/courseDetails/${id}`);
  };
  return (
    <>
      <Card
        className="card-shadow video-card br-3 c-pointer"
        key={props.id}
        onClick={() => {
          goToCourse(props.id);
        }}
      >
        <Card.Img
          className="video-thumbnail"
          variant="top"
          src={
            props.image ? props.image : require("../../Assets/images/logo.png")
          }
        ></Card.Img>
        <Card.Body className="bg-theme-gray-14 p-2">
          <Card.Title className="video-title mb-0">
            {props.name ? props.name : "NA"}
          </Card.Title>
          <div className="d-flex justify-content-between align-items-center video-info">
            <small className="col-8 append-ellipsis">
              {props.cls ? props.cls : "NA"}
            </small>
            <small className="append-ellipsis">
              {props.subject ? props.subject : "NA"}
            </small>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default HomeCard;
