import React, { useState, useEffect } from "react";
import { Card, Container, Button } from "react-bootstrap";
import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";

import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Hooks/api/Api";
import Loader from "../../components/Loader/Loader";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { successMsg } from "../../Helpers/Success";
import { errorMsg } from "../../Helpers/Error";
import SuccessToast from "../../components/SuccessMsg/SuccessToast";
import * as Yup from "yup";

const EditEducationalLevel = () => {
  const heading = "Education Level";
  const navigate = useNavigate();
  const currentData = JSON.parse(localStorage.getItem("currentUserData"));
  const [subHeading, setsubHeading] = useState(
    "Your current educational background"
  );

  let initialValues = {
    level: currentData.level,
    subLevel: currentData.subLevel,
    board: currentData.board,
  };
  const [formValues, setFormValues] = useState(() => initialValues);
  const [success, setSuccess] = useState("");
  const [loader, setLoader] = useState(false);
  const [eduLevel, setEduLevel] = useState([]);
  const [selectedSubLevel, setSelectedSubLevel] = useState([]);
  const [error, setError] = useState("");

  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const { id } = useParams();

  const educationLevel = () => {
    setLoader(true);
    axiosInstance
      .post(`levels/listLevel`, {})
      .then((result) => {
        setLoader(false);
        setEduLevel(result.data.data);
        if (initialValues && initialValues.level) {
          result.data.data.forEach((level) => {
            if (initialValues.level === level["_id"]) {
              setSelectedSubLevel(level["subLevels"]);
            }
          });
        }
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  const filterSubLevel = (e) => {
    const levelID = e;
    eduLevel.forEach((level) => {
      if (levelID === level["_id"]) {
        setSelectedSubLevel(level["subLevels"]);
      }
    });
  };

  useEffect(() => {
    educationLevel();
    if (userData.type === "parent") {
      setsubHeading(`Details of your student's Education`);
    }
  }, []);

  const EducationSchema = Yup.object().shape({
    level: Yup.string().required("Level Is Required"),
  });

  const handleSubmit = (values) => {
    console.log(values);
    setLoader(true);
    axiosInstance
      .post(`/customers/update/${id}`, values)
      .then((response) => {
        setSuccess(successMsg(response));
        setTimeout(() => {
          setLoader(false);
          setSuccess("");
          navigate("/user/profile");
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0">
          <Card className="br-top" style={{ height: "85vh" }}>
            <Card.Body className="d-flex align-items-center flex-column">
              <Formik
                initialValues={formValues}
                onSubmit={handleSubmit}
                validationSchema={EducationSchema}
              >
                {(values) => (
                  <Form
                    className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4 d-flex flex-column gap-2" //onSubmit={handelSubmit}
                    noValidate
                  >
                    <div className="d-flex justify-content-start ">
                      <FormAccessLayout
                        heading={heading}
                        heading_sub={subHeading}
                      ></FormAccessLayout>
                    </div>

                    <Field
                      name="level"
                      as="select"
                      onChange={(e) => {
                        values.handleChange(e);
                        filterSubLevel(e.currentTarget.value);
                      }}
                      className="form-select theme-shadow"
                    >
                      <option value={""}>You'r Currently</option>
                      {eduLevel.map((listName) => (
                        <option
                          value={listName._id}
                          name={listName._id}
                          key={listName._id}
                          id="level"
                        >
                          {listName.name}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="div"
                      name="level"
                      className="error text-danger"
                    />

                    <Field
                      name="subLevel"
                      as="select"
                      className="form-select theme-shadow"
                      onChange={(e) => {
                        values.handleChange(e);
                      }}
                    >
                      <option value={""}>Class, Grade, Year</option>
                      {selectedSubLevel.map((subLevel) => (
                        <option value={subLevel["_id"]} key={subLevel["_id"]}>
                          {subLevel["name"]}
                        </option>
                      ))}
                    </Field>

                    <Field
                      type="text"
                      autoComplete="off"
                      name="board"
                      id="board"
                      className="form-control mb-2 theme-shadow text-left"
                      placeholder="Board"
                    />

                    <Button
                      type="submit"
                      className="border-0  mt-2 col-12 theme-shadow "
                      variant="button-color-1 color-theme-white"
                    >
                      Update
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
        {success && <SuccessToast success={success}></SuccessToast>}
      </div>
    </>
  );
};

export default EditEducationalLevel;
