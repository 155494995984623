import React from "react";
import { Image } from "react-bootstrap";

const AppAccessLayout = (props) => {
  return (
    <>
      <div className="d-flex justify-content-center">
        <Image
          fluid
          src={require("../../Assets/images/logo.png")}
          className="elem-w-50"
        ></Image>
      </div>
      <div className="row text-center">
        <h3 className="text-dark fw-bolder m-0">{props.heading}</h3>
        <h3 className="text-success fw-bolder m-0">{props.heading_sub}</h3>
      </div>
    </>
  );
};

export default AppAccessLayout;
