import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const CourseType = (props) => {
  const navigate = useNavigate();

  const goToTopics = () => {
    navigate(`/user/explore/${props.id}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card
      key={props.id}
      className="c-pointer mb-2 theme-shadow"
      onClick={goToTopics}
    >
      <Card.Body className="d-flex justify-content-between align-items-start p-2 ">
        <div className="d-flex flex-column align-items-start">
          <div className="small fw-bold color-button-color-1">{props.name}</div>
          <small className=" small color-bs-gray-800 append-ellipsis">
            {props.description}
          </small>
        </div>
        <div className="m-2 p-1 bg-button-color-1 d-flex align-items-center border-radius-5 color-theme-white rounded">
          <AiOutlineRight />
        </div>
      </Card.Body>
    </Card>
  );
};

export default CourseType;
