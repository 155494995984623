import React from "react";
import { Container, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";

const CardList = (props) => {
  const navigate = useNavigate();

  return (
    <>
      <Card
        className="card-shadow video-card c-pointer m-1"
        key={props.courseID}
        onClick={() => navigate("/user/courseDetails/" + props.courseID)}
      >
        <Card.Img
          className="video-thumbnail"
          variant="top"
          src={
            props.courseImage
              ? props.courseImage
              : require("../../Assets/images/logo.png")
          }
        ></Card.Img>
        <Card.Body className=" p-1 rounded-bottom">
          <Card.Title className="video-title mb-0">
            {props.courseName ? props.courseName : "NA"}
          </Card.Title>
          <div className="d-flex justify-content-between align-items-center video-info color-theme-gray-9">
            <div className="small append-ellipsis col-8 col-md-6">
              {props.categoryName ? props.categoryName : "NA"}
            </div>
            <div className="small col-4 col-md-6 append-ellipsis">
              {props.topicName ? props.topicName : "NA"}
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default CardList;
