import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { Button } from "react-bootstrap";
import { Card, Container } from "react-bootstrap";
import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../Hooks/api/Api";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import SuccessToast from "../../components/SuccessMsg/SuccessToast";
import { errorMsg } from "../../Helpers/Error";
import { successMsg } from "../../Helpers/Success";

const EditCourse = () => {
  const navigate = useNavigate();
  const heading = "Intrested In";
  const [subHeading, setsubHeading] = useState("Courses You are interested in");
  const currentData = JSON.parse(localStorage.getItem("currentUserData"));
  const initialValues = { category: currentData.category };
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [selectCourse, setSelectCourse] = useState([]);
  const { id } = useParams();

  const courseList = () => {
    setLoader(true);
    axiosInstance
      .get("courses/listAllCategory/")

      .then((result) => {
        setSelectCourse(result.data.data);
        setLoader(false);
      })
      .catch((error) => {
        if (error.result) {
          console.log(error.result);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    if (currentData.type === "parent") {
      setsubHeading("Courses your student is interested in");
    }
    courseList();
  }, []);

  const validationCourses = Yup.object({
    category: Yup.array().min(1, "Select Category"),
  });

  const handleSubmit = (values) => {
    setLoader(true);
    axiosInstance
      .post(`/customers/update/${id}`, values)
      .then((response) => {
        setSuccess(successMsg(response));
        setTimeout(() => {
          setLoader(false);
          setSuccess("");
          navigate("/user/profile");
        }, 2000);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0">
          <Card className="br-top" style={{ height: "85vh" }}>
            <Card.Body className="d-flex align-items-center flex-column noselect">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationCourses}
              >
                {({ values, errors, touched }) => (
                  <Form
                    className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4 d-flex flex-column gap-2"
                    id="courseCategory"
                  >
                    <div className="d-flex justify-content-start ">
                      <FormAccessLayout
                        heading={heading}
                        heading_sub={subHeading}
                      ></FormAccessLayout>
                    </div>
                    {selectCourse.map((list) => (
                      <Card
                        key={list["_id"]}
                        className="align-items-center card elem-h-45 flex-row shadow-sm"
                      >
                        <Card.Body>
                          <div
                            className="font-size-3x text-secondary text-start"
                            key={list["_id"]}
                          >
                            {list["name"]}
                          </div>
                        </Card.Body>

                        <div className="form-check form-check-inline">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="category"
                            id={list.id}
                            value={list["_id"]}
                          />
                        </div>
                      </Card>
                    ))}
                    <ErrorMessage
                      component="div"
                      name="category"
                      className="error text-center small text-danger"
                    />

                    <Button
                      type="submit"
                      className="border-0  mt-2 col-12  theme-shadow "
                      variant="button-color-1 color-theme-white"
                    >
                      Update
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
        {success && <SuccessToast success={success}></SuccessToast>}
      </div>
    </>
  );
};

export default EditCourse;
