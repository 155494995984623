import React from "react";
import { Card } from "react-bootstrap";

const SuccessToast = (props) => {
  return (
    <>
      <div className="d-flex position-absolute bottom-0 mb-3 justify-content-center container-fluid layer-top">
        <Card className="bg-success py-1 px-2 br-4">
          <div className="text-light font-size-2x text-center font-weight-5x">
            {props.success}
          </div>
        </Card>
      </div>
    </>
  );
};

export default SuccessToast;
