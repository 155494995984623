import React from "react";

const Overlay = (props) => {
  const hideSideBar = () => {
    const sideBar = document.getElementById("sideBar");
    const overlay = document.getElementById("overlay");
    sideBar.style.width = "0";
    overlay.style.width = "0";
  };
  return (
    <>
      <div className="overlay" id={props.id} onClick={hideSideBar}></div>
    </>
  );
};

export default Overlay;
