import React from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import Loginform from "../Pages/login/Loginform";
import TermsConditions from "../Pages/termsConditions/TermsConditions";
import LoginPassword from "../Pages/loginPassword/LoginPassword";
import ResetPassword from "../Pages/resetPassword/ResetPassword";
import LoginOtp from "../Pages/otp/LoginOtp";
import RegistrationForm from "../Pages/register/RegistrationForm";
import StudentRegister from "../Pages/studentregister/StudentRegister";
import InfoForm from "../Pages/info/InfoForm";
import SignUp from "../Pages/signup/SignUp";
import EducationLevel from "../Pages/educationLevel/EducationLevel";
import Courses from "../Pages/courses/Courses";
import InsideRegister from "../Pages/insideRegister/InsideRegister";
import SuccesPagee from "../Pages/succesPagee/SuccesPagee";
import StudentPage from "../Pages/studentPage/StudentPage";
import ParentPage from "../Pages/parentPage/ParentPage";
import NewPassword from "../Pages/newPassword/NewPassword";
import NumberOtp from "../Pages/numberOtp/NumberOtp";

const AppAccessRouting = () => {
  const MissingRoute = () => {
    return <Navigate to={{ pathname: "/SignUp" }} />;
  };

  return (
    <>
      <Routes>
        <Route path="/signup" element={<SignUp></SignUp>}></Route>
        <Route path="/login" element={<Loginform></Loginform>}></Route>
        <Route
          path="/termsConditions"
          element={<TermsConditions></TermsConditions>}
        ></Route>
        <Route
          path="/login-password"
          element={<LoginPassword></LoginPassword>}
        ></Route>
        <Route
          path="/resetPassword"
          element={<ResetPassword></ResetPassword>}
        ></Route>
        <Route path="/otp" element={<LoginOtp></LoginOtp>}></Route>
        <Route path="/numberOtp" element={<NumberOtp></NumberOtp>}></Route>
        <Route
          path="/newPassword"
          element={<NewPassword></NewPassword>}
        ></Route>
        <Route
          path="/register"
          element={<RegistrationForm></RegistrationForm>}
        ></Route>
        <Route
          path="/insideRegister"
          element={<InsideRegister></InsideRegister>}
        ></Route>
        <Route
          path="/studentregister"
          element={<StudentRegister></StudentRegister>}
        ></Route>
        <Route
          path="/succesPagee"
          element={<SuccesPagee></SuccesPagee>}
        ></Route>
        <Route
          path="/studentPage"
          element={<StudentPage></StudentPage>}
        ></Route>
        <Route path="/parentPage" element={<ParentPage></ParentPage>}></Route>
        <Route
          path="/educationLevel"
          element={<EducationLevel></EducationLevel>}
        ></Route>
        <Route path="/courses" element={<Courses></Courses>}></Route>

        <Route path="*" element={<MissingRoute />}></Route>
      </Routes>
    </>
  );
};

export default AppAccessRouting;
