import React, { useState, useEffect } from "react";
import { Card, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import Loader from "../../components/Loader/Loader";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import AtoZCards from "../atozCards/AtoZCards";
import FooterApp from "../../components/appAdminLayout/FooterApp";
import { errorMsg } from "../../Helpers/Error";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import axiosInstance from "../../Hooks/api/Api";

const ExploreInside = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [topics, setTopics] = useState([]);
  const [error, setError] = useState("");
  const [search, setSearch] = useState({});
  const [sort, setSort] = useState(-1);
  let { cid } = useParams();
  const pageName = "Explore";

  const [courseName, setcourseName] = useState("");

  const getTopics = (search) => {
    setLoader(true);
    axiosInstance
      .post("courses/listTopic/" + cid, search)
      .then((data) => {
        setLoader(false);
        setTopics(
          data.data.data.sort(function (a, b) {
            return sortArray(b.name, a.name);
          })
        );
        setcourseName(data.data.data[0].category.name);
        sortData();
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };
  const sortData = () => {
    sort === -1 ? setSort(1) : setSort(-1);
    if (sort === -1) {
      topics.sort(function (a, b) {
        return sortArray(b.name, a.name);
      });
    }
    if (sort === 1) {
      topics.sort(function (a, b) {
        return sortArray(a.name, b.name);
      });
    }
  };

  const sortArray = (a, b) => {
    a = a.toLowerCase();
    b = b.toLowerCase();
    return a < b ? -1 : a > b ? 1 : 0;
  };

  useEffect(() => {
    getTopics(search);
  }, [search]);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <HeaderApp pageName={pageName}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 position-relative mb-5">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex justify-content-start align-items-center">
                <IoIosArrowBack
                  className="square-25 text-white bg-button-color-1 rounded-1 me-2"
                  onClick={() => navigate(-1)}
                ></IoIosArrowBack>
                <h6 className=" fw-bold text-capitalize color-button-color-1 mb-0">
                  {courseName}
                </h6>
              </div>
              <small
                className="fw-bold color-button-color-1 c-pointer"
                onClick={sortData}
              >
                {sort === -1 ? "AZ" : "ZA"}
              </small>
            </div>
            <div className="mt-3 d-flex flex-wrap gap-2 justify-content-start pb-5">
              {topics.map((topic) => (
                <AtoZCards
                  courseID={topic._id}
                  key={topic._id}
                  topicName={topic.name}
                  topicDes={topic.description}
                  categoryDes={topic.category.description}
                ></AtoZCards>
              ))}
            </div>
          </Card>
        </Container>
        <FooterApp></FooterApp>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default ExploreInside;
