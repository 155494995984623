import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import FooterApp from "../../components/appAdminLayout/FooterApp";
import { useNavigate } from "react-router-dom";
import { AiOutlineRight } from "react-icons/ai";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import Loader from "../../components/Loader/Loader";
import axiosInstance from "../../Hooks/api/Api";

const ProfileFile = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userData._id;

  const viewPersonalInfo = () => {
    if (userData.type === "parent") {
      navigate("/user/personalFile/" + userId);
    }
    if (userData.type === "student") {
      navigate("/user/studentDetails/" + userId);
    }
  };

  const detailsProfile = () => {
    setLoader(true);
    axiosInstance
      .get("customers/getDetails/" + userId)
      .then((resp) => {
        setLoader(false);
        localStorage.setItem("currentUserData", JSON.stringify(resp.data.data));
      })
      .catch((error) => {
        setLoader(false);
        if (error.resp) {
          console.log(error.resp);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    detailsProfile();
  }, []);

  return (
    <>
      {loader && <Loader></Loader>}
      <div className="main-wrapper">
        <HeaderApp pageName={"Profile"}></HeaderApp>
        <Container id="homePage" fluid className="p-0 mt-5 pt-3">
          <Card
            className="br-top rounded-3 min-vh-100"
            style={{ marginTop: "50px" }}
          >
            <Card.Body className="position-relative">
              <div
                className="d-flex flex-column align-items-center"
                style={{ marginTop: "-60px" }}
              >
                <img
                  className="rounded-circle bg-light "
                  src={require("../../Assets/images/favicon.png")}
                  alt="Companylogo"
                />
                <h6 className="text-black fw-bold m-1">
                  {userData.type === "parent"
                    ? userData.parent.name
                    : userData.name}
                </h6>
                <p className="text-black fw-light text-capitalize">
                  {" "}
                  {"I'm "}
                  {userData.type ? userData.type : ""}{" "}
                </p>
              </div>
              <div className="d-flex flex-column gap-2">
                <div className="" onClick={viewPersonalInfo}>
                  <Card className="c-pointer d-flex flex-row elem-h-55 align-items-center rounded-1 theme-shadow">
                    <Card.Body>
                      <Card.Title className="color-theme-color-2 h6">
                        Personal Details
                      </Card.Title>

                      <Card.Subtitle className="text-secondary small font-size-1x">
                        {"Few Personal Details About you"}
                      </Card.Subtitle>
                    </Card.Body>
                    <div className="m-2 p-1 bg-button-color-1 d-flex align-items-center border-radius-5 color-theme-white rounded">
                      <AiOutlineRight />
                    </div>
                  </Card>
                </div>
                {userData.type === "parent" && (
                  <div
                    className=""
                    onClick={() =>
                      navigate("/user/parentsStudentInfo/" + userId)
                    }
                  >
                    <Card className="c-pointer d-flex flex-row elem-h-55 align-items-center rounded-1 theme-shadow">
                      <Card.Body>
                        <Card.Title className="color-theme-color-2 h6">
                          Sudent Details
                        </Card.Title>

                        <Card.Subtitle className="text-secondary small font-size-1x">
                          {"Basic Details About the student"}
                        </Card.Subtitle>
                      </Card.Body>
                      <div className="m-2 p-1 bg-button-color-1 d-flex align-items-center border-radius-5 color-theme-white rounded">
                        <AiOutlineRight />
                      </div>
                    </Card>
                  </div>
                )}

                <div
                  className=""
                  onClick={() =>
                    navigate("/user/editEducationalLevel/" + userId)
                  }
                >
                  <Card className="c-pointer d-flex flex-row elem-h-55 align-items-center rounded-1 theme-shadow">
                    <Card.Body>
                      <Card.Title className="color-theme-color-2 h6">
                        Education Level
                      </Card.Title>
                      <Card.Subtitle className="text-secondary small font-size-1x">
                        {userData.type === "parent"
                          ? "Details of your student's education"
                          : "Your current educational background"}
                      </Card.Subtitle>
                    </Card.Body>
                    <div className=" m-2 p-1 bg-button-color-1 d-flex align-items-center border-radius-5 color-theme-white rounded">
                      <AiOutlineRight />
                    </div>
                  </Card>
                </div>

                <div
                  className=""
                  onClick={() => navigate("/user/editCourses/" + userId)}
                >
                  <Card className="c-pointer d-flex flex-row elem-h-55 align-items-center rounded-1 theme-shadow">
                    <Card.Body>
                      <Card.Title className="color-theme-color-2 h6">
                        Courses
                      </Card.Title>
                      <Card.Subtitle className="text-secondary small font-size-1x">
                        {userData.type === "parent"
                          ? "Courses your student is interested in"
                          : "Courses you are interested in "}
                      </Card.Subtitle>
                    </Card.Body>
                    <div className=" m-2 p-1 bg-button-color-1 d-flex align-items-center border-radius-5 color-theme-white rounded">
                      <AiOutlineRight />
                    </div>
                  </Card>
                </div>

                <div
                  className=""
                  onClick={() => navigate("/user/updatePassword/" + userId)}
                >
                  <Card className="c-pointer d-flex flex-row elem-h-55 align-items-center rounded-1 theme-shadow">
                    <Card.Body>
                      <Card.Title className="color-theme-color-2 h6">
                        Update Password
                      </Card.Title>
                      <Card.Subtitle className="text-secondary small font-size-1x">
                        {"You can change your password for better security"}
                      </Card.Subtitle>
                    </Card.Body>
                    <div className=" m-2 p-1 bg-button-color-1 d-flex align-items-center border-radius-5 color-theme-white rounded">
                      <AiOutlineRight />
                    </div>
                  </Card>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Container>

        <FooterApp></FooterApp>
      </div>
    </>
  );
};

export default ProfileFile;
