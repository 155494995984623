import React, { useState, useEffect } from "react";
import InsideRegister from "../insideRegister/InsideRegister";
import EducationLevel from "../educationLevel/EducationLevel";
import Courses from "../courses/Courses";
import ParentPage from "../parentPage/ParentPage";
import SuccesPagee from "../succesPagee/SuccesPagee";

const RegistrationForm = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    dob: "",
    password: "",
    cpassword: "",
    type: "",
    gender: "",
    parent: {
      name: "",
      dob: "",
      relationship: "",
    },
    level: "",
    subLevel: "",
    category: [],
    board: "",
  });
  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  useEffect(() => {});

  const steps = [
    <ParentPage next={handleNextStep} data={data} />,
    <InsideRegister next={handleNextStep} prev={handlePrevStep} data={data} />,
    <EducationLevel next={handleNextStep} prev={handlePrevStep} data={data} />,
    <Courses next={handleNextStep} prev={handlePrevStep} data={data} />,
    <SuccesPagee next={handleNextStep} prev={handlePrevStep} data={data} />,
  ];

  const saved = localStorage.getItem("type");
  const type = { type: saved };
  const returnedTarget = Object.assign(data, type);

  return <div>{steps[currentStep]}</div>;
};

export default RegistrationForm;
