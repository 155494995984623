import React, { useEffect, useState } from "react";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";
import Loader from "../../components/Loader/Loader";
import { Card, Container } from "react-bootstrap";
import axiosInstance from "../../Hooks/api/Api";

function TermsConditions() {
  const [loader, setLoader] = useState(false);

  const termsFile = () => {
    setLoader(true);
    axiosInstance
      .get("configurations/getDetails")
      .then((resp) => {
        setLoader(false);

        document.querySelector("#details").innerHTML = resp.data.data.policy;
      })
      .catch((error) => {
        setLoader(false);
        if (error.resp) {
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    termsFile();
  }, []);

  return (
    <>
      {loader && <Loader></Loader>}
      <div className="main-wrapper">
        <HeaderApp pageName={"Terms & Conditions"}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className=" theme-white rounded-3 min-vh-100">
            <div id="details"></div>
          </Card>
        </Container>
      </div>
    </>
  );
}

export default TermsConditions;
