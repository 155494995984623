import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import axiosInstance from "../../Hooks/api/Api";
import { errorMsg } from "../../Helpers/Error";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import Loader from "../../components/Loader/Loader";
import FooterApp from "../../components/appAdminLayout/FooterApp";
import NavApp from "../../components/appAdminLayout/NavApp";
import HomeCard from "../home/HomeCard";
import HeaderApp from "../../components/appAdminLayout/HeaderApp";

const MyCourses = () => {
  const pageName = "My Courses";
  const [error, setError] = useState("");
  const [searchData, setSearchData] = useState({});
  const [loader, setLoader] = useState(false);
  const [courses, setCourses] = useState([]);

  const getCourses = () => {
    setLoader(true);
    axiosInstance
      .post("courses/myCourses")
      .then((data) => {
        setLoader(false);
        setCourses(data.data.courses);
      })
      .catch((error) => {
        setLoader(false);
        setError(errorMsg(error));
        setTimeout(() => {
          setError("");
        }, 2000);
      });
  };

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <HeaderApp pageName={pageName}></HeaderApp>
        <Container fluid className="p-0 mt-5 pt-3">
          <Card className="br-top rounded-3 min-vh-100 p-3 mb-5 position-relative">
            <Card.Body className="p-0">
              {courses.map((course, index) => (
                <div
                  className="d-flex justify-content-start flex-column mb-2"
                  key={index}
                >
                  <h6 className="fw-bold color-button-color-1 mb-0">
                    {course["name"] ? course["name"] : "NA"}
                  </h6>
                  <div className="d-flex flex-wrap mt-2 gap-2 pb-5">
                    {course["courses"].map((item) => (
                      <HomeCard
                        id={item._id}
                        key={item._id}
                        image={item.media}
                        name={item.name}
                        cls={item.category.name}
                        subject={item.topic.name}
                      ></HomeCard>
                    ))}
                  </div>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Container>
        <FooterApp></FooterApp>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default MyCourses;
