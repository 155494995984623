import React, { useState,useEffect } from "react";

import { Button, Card, Container } from "react-bootstrap";
import { BsCheckCircle } from "react-icons/bs";
import { useNavigate  } from "react-router-dom";

import Loader from "../../components/Loader/Loader";
import ErrorToast from "../../components/errorMsg/ErrorToast";

const SuccesPagee = () => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");

  const loginSucces = () => {
    // localStorage.setItem("login", true);
    navigate("/user/home")
  }

  // useEffect(() =>{
  //   loginSucces();
  //   let login = localStorage.getItem('login');
  //   if (login){
  //     navigate('/user/home');
  //   }
  // });

  return (
    <>
      {loader && <Loader></Loader>}
      <Container fluid className="p-0 position-absolute bottom-0">
        <Card className="br-top " style={{ height: "60vh" }}>
          <Card.Body className="d-flex align-items-center flex-column mt-4 m-2">
            <h1>Done!</h1>
            <p className="text-secondary text-small">
            Saraswati Sishu Vidya Mandir is setting up courses just for you!
            </p>

            <div className="p-20">
              <BsCheckCircle size="10em" color="#09ba09" />
            </div>

            <Button
              onClick={loginSucces}
              type="submit"
              className="border-0  m-4 col-12 col-md-3  col-xl-3 theme-shadow position-absolute bottom-0"
              variant="button-color-1 color-theme-white"
              id="submitButton"
            >
              Start Learning
            </Button>
          </Card.Body>
        </Card>
      </Container>
      {error && <ErrorToast error={error}></ErrorToast>}
    </>
  );
};

export default SuccesPagee;
