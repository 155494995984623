import React from "react";


const FormAccessLayout = (props) => {
  return (
    <>
   
      <div className="row text-left mb-3">
        <h3 className="text-dark fw-bolder text-start m-0">{props.heading}</h3>
        <small className="color-theme-gray-9 fw-smaler text-start m-0">{props.heading_sub}</small>
      </div>
    </>
  );
};

export default FormAccessLayout;