import { Card, Container, Button } from "react-bootstrap";
import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";
import { AiOutlineLeft } from "react-icons/ai";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const InsideRegister = (props) => {
  const heading = "Student Details";
  const heading_sub = "Step 2 - Basic details about the student";

  const InsideSchema = Yup.object().shape({
    parent: Yup.object().shape({
      name: Yup.string().required("Name is Required"),
      dob: Yup.string().required("Date Of Birth Is Required"),
    }),

    gender: Yup.string().required("Gender Is Required"),
  });

  const changeType = () => {
    const elementID = document.getElementById("dob");

    elementID.type = elementID.type === "text" ? "date" : "text";
  };

  const handleSubmit = (values) => {
    props.next(values, true);
  };

  return (
    <>
     <div className="main-wrapper">
      <Container fluid className="p-0 position-absolute bottom-0">
        <Card className="br-top cardheight">
          <Card.Body className="d-flex align-items-center flex-column">
            <Formik
              initialValues={props.data}
              onSubmit={handleSubmit}
              validationSchema={InsideSchema}
            >
              {({ values, errors, touched, isValidating }) => (
                <Form
                  className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4 d-flex flex-column gap-2"
                  noValidate
                >
                  <div className="d-flex justify-content-start ">
                    <AiOutlineLeft
                      className="color-button-color-1 font-size-8x c-pointer"
                      onClick={() => props.prev(values)}
                    />
                    <FormAccessLayout
                      heading={heading}
                      heading_sub={heading_sub}
                    ></FormAccessLayout>
                  </div>
                  <Field
                    type="text"
                    autoComplete="off"
                    name="parent.name"
                    id="name"
                    placeholder="Student Name *"
                    className="form-control theme-shadow text-left"
                  />
                  <ErrorMessage
                    component="div"
                    name="parent.name"
                    className="error text-danger"
                  />

                  <Field
                    as="select"
                    className="form-control theme-shadow text-left"
                    name="parent.relationship"
                    id="relationship"
                  >
                    <option value={""} disabled>
                      Relationship with Student
                    </option>
                    <option value={"father"}>Father</option>
                    <option value={"mother"}>Mother</option>
                    <option value={"brother"}>Brother</option>
                    <option value={"sister"}>Sister</option>
                    <option value={"uncle"}>Uncle</option>
                    <option value={"aunt"}>Aunt</option>
                    <option value={"husband"}>Husband</option>
                    <option value={"wife"}>Wife</option>
                    <option value={"brother"}>Guardian</option>
                  </Field>

                  <Field
                    type="text"
                    autoComplete="off"
                    name="parent.dob"
                    id="dob"
                    onFocus={changeType}
                    onBlur={changeType}
                    className="form-control mb-0 theme-shadow text-left"
                    placeholder="Date of Birth *"
                  />

                  <ErrorMessage
                    component="div"
                    name="parent.dob"
                    className="error text-danger"
                  />

                  <Field
                    as="select"
                    className="form-control theme-shadow text-left"
                    name="gender"
                    id="gender"
                  >
                    <option value={""} disabled>
                      Gender *
                    </option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                    <option value={"other"}>Other</option>
                  </Field>

                  <ErrorMessage
                    component="div"
                    name="gender"
                    className="error text-danger"
                  />

                  <Button
                    type="submit"
                    className="border-0  mt-2 col-12  theme-shadow "
                    variant="button-color-1 color-theme-white"
                  >
                    Continue
                  </Button>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </Container>
      </div>
    </>
  );
};

export default InsideRegister;
