import React, { useState, useEffect } from "react";
import Loader from "../../components/Loader/Loader";
import { Button } from "react-bootstrap";
import { Card, Container } from "react-bootstrap";
import FormAccessLayout from "../../components/appAccessLayout/FormAccessLayout";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import axiosInstance from "../../Hooks/api/Api";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { errorMsg } from "../../Helpers/Error";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const Courses = (props, steps, currentStep) => {
  const heading = "Intrested In";

  const [loader, setLoader] = useState(false);
  const [selectCourse, setSelectCourse] = useState([]);
  const [page, setPage] = useState(3);
  const [pageTitle, setPageTitle] = useState("Courses you are interested in");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const courseList = () => {
    setLoader(true);
    axiosInstance
      .get("courses/listAllCategory/")

      .then((result) => {
        setSelectCourse(result.data.data);
        setLoader(false);
      })
      .catch((error) => {
        if (error.result) {
          console.log(error.result);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("type") === "parent") {
      setPage(4);
      setPageTitle(`Courses your student is interested in`);
    }
    courseList();
  }, []);

  const validationCourses = Yup.object({
    category: Yup.array().min(1, "Select Ctegory"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    delete values["cpassword"];

    try {
      const response = await axiosInstance
        .post("customers/register", values)
        .then((response) => {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data["data"])
          );

          localStorage.setItem("token", response.data.data.token.token);

          props.next(values, true);
          localStorage.setItem("login", true);
        });
      console.log(response.data);
    } catch (error) {
      setLoader(false);
      setError(errorMsg(error));
      setTimeout(() => {
        setError("");
      }, 3000);
    } finally {
      setSubmitting(false);
    }
  };




  return (
    <>
      <div className="main-wrapper">
        {loader && <Loader></Loader>}
        <Container fluid className="p-0 position-absolute bottom-0 ">
          <Card className="br-top cardheight">
            <Card.Body className="d-flex align-items-center flex-column ">
              <Formik
                initialValues={props.data}
                onSubmit={handleSubmit}
                validationSchema={validationCourses}
              >
                {({ values, errors, touched }) => (
                  <Form
                    className="mt-3 col-12 col-md-6 col-xl-6 text-center px-4 d-flex flex-column gap-2"
                    id="courseCategory"
                  >
                    <div className="d-flex justify-content-start ">
                      <AiOutlineLeft
                        className="color-button-color-1 font-size-8x c-pointer"
                        onClick={() => props.prev(values)}
                      />

                      <FormAccessLayout
                        heading={heading}
                        heading_sub={`Step ${page}- ${pageTitle} `}
                      ></FormAccessLayout>
                    </div>
                    {selectCourse.map((list) => (
                      <Card
                        key={list["_id"]}
                        className="align-items-center card elem-h-45 flex-row shadow-sm"
                      >
                        <Card.Body>
                          <div
                            className="font-size-3x text-secondary text-start"
                            key={list["_id"]}
                          >
                            {list["name"]}
                          </div>
                        </Card.Body>

                        <div className="form-check form-check-inline">
                          <Field
                            className="form-check-input"
                            type="checkbox"
                            name="category"
                            id={list.id}
                            value={list["_id"]}
                          />
                        </div>
                      </Card>
                    ))}
                    <ErrorMessage
                      component="div"
                      name="category"
                      className="error text-center small text-danger"
                    />

                    <Button
                      type="submit"
                      className="border-0  mt-2 col-12  theme-shadow "
                      variant="button-color-1 color-theme-white"
                    >
                      Continue
                    </Button>
                  </Form>
                )}
              </Formik>
            </Card.Body>
          </Card>
        </Container>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default Courses;
