import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

const SideBar = (props) => {
  const navigate = useNavigate();
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const userData = JSON.parse(localStorage.getItem("userDetails"));
  const userName = `${userData && userData?.firstName} ${
    userData && userData?.lastName
  }`;
  const userMail = `${userData && userData?.email}`;

  const openModal = () => {
    setFullscreen(true);
    setShow(true);
  };

  const closeModal = () => {
    setShow(false);
    setFullscreen(false);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
  };
  return (
    <>
      <div className="sideNav" id={props.id}>
        <div className="sideNav-inner">
          <div className="sideNav-inner-img">
            <img src="/logo192.png" alt="logo"></img>
            <h5 className="m-0">{userName}</h5>
            <small>{userMail}</small>
          </div>
        </div>
        <div className="sideNav-menu">
          <NavLink to={"/home"}>My Account</NavLink>
          <NavLink to={"/home"}>Settings</NavLink>
        </div>
        <div className="sideNav-btn">
          <button
            className="btn btn-light rounded-3 text-uppercase w-100"
            onClick={openModal}
          >
            Logout
          </button>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <div className="modal-content logout-modal">
          <Modal.Body className="align-items-center d-flex flex-column font-size-2x fw-bold justify-content-center modal-body text-light">
            Are you sure you want to logout ?
            <div className="d-flex flex-column align-items-center position-absolute bottom-0 pb-5 w-100">
              <button
                className="btn btn-light rounded-3 text-uppercase mb-2 w-50"
             
                onClick={logout}
              >
                logout
              </button>
              <button
                className="btn btn-outline-success text-light rounded-3 text-uppercase w-50"
                onClick={closeModal}
              >
                cancel
              </button>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
};

export default SideBar;
