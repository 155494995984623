import React from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function AtoZCards(props) {
  const navigate = useNavigate();

  const goToCourse = () => {
    localStorage.setItem("topicName", props.topicName);
    navigate("/user/exploreCourses/" + props.courseID);
  };

  useEffect(() => {}, []);
  return (
    <>
      <div className="">
        <Card
          className="border c-pointer card elem-h-100 elem-w-165 rounded-1 shadow-sm"
          onClick={goToCourse}
          key={props.id}
        >
          <Card.Body>
            <div className="d-flex flex-column text-center align-items-center">
              <small className="color-button-color-1 append-ellipsis fw-bold">
                {props["topicName"] ? props["topicName"] : "NA"}
              </small>
              <small className="text-secondary append-ellipsis text-center">
                {props["topicDes"] ? props["topicDes"] : "NA"}
              </small>
              <small className="text-secondary append-ellipsis text-center">
                {props["categoryDes"] ? props["categoryDes"] : "NA"}
              </small>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export default AtoZCards;
