import React, { useState, useEffect } from "react";
import SignUp from "../signup/SignUp";
import EducationLevel from "../educationLevel/EducationLevel";
import Courses from "../courses/Courses";
import StudentPage from "../studentPage/StudentPage";
import SuccesPagee from "../succesPagee/SuccesPagee";
import { Formik, Form, Field } from "formik";

const StudentRegister = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNo: "",
    gender: "",
    dob: "",
    cpassword: "",
    password: "",
    type: "",
    level: "",
    subLevel: "",
    category: [],
    board: "",
  });

  const [currentStep, setCurrentStep] = useState(0);

  const handleNextStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };

  const steps = [
    <StudentPage next={handleNextStep} prev={handlePrevStep} data={data} />,
    <EducationLevel next={handleNextStep} prev={handlePrevStep} data={data} />,
    <Courses next={handleNextStep} prev={handlePrevStep} data={data} />,
    <SuccesPagee next={handleNextStep} prev={handlePrevStep} data={data} />,
  ];

  const saved = localStorage.getItem("type");
  const type = { type: saved };
  const returnedTarget = Object.assign(data, type);

  return <div>{steps[currentStep]}</div>;
};

export default StudentRegister;
