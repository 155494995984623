import { Stream } from "@cloudflare/stream-react";
import React, { useRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import $ from "jquery";
import axiosInstance from "../../Hooks/api/Api";
import ErrorToast from "../../components/errorMsg/ErrorToast";
import { errorMsg } from "../../Helpers/Error";

const PlayVideo = () => {
  const location = useLocation();
  const [error, setError] = useState();
  let streamRef = useRef(null);

  const catchTimeUpdate = () => {
    const video = streamRef?.current;
    if (video) {
      const videoTime = Math.ceil(video.duration);
      const currentVideoTime = Math.ceil(video.currentTime);

      let saveTimeData = {};
      if (videoTime === currentVideoTime) {
        saveTimeData = {
          time: currentVideoTime,
          completed: true,
        };
      } else {
        saveTimeData = {
          time: currentVideoTime,
          completed: false,
        };
      }
      axiosInstance
        .post(`chapters/saveTime/${location.state._id}`, saveTimeData)
        .then((response) => {})
        .catch((error) => {
          setError(errorMsg(error));
          setTimeout(() => {
            setError("");
          });
        });
    }
  };

  useEffect(() => {
    if (window.history && window.history.pushState) {
      $(window).on("popstate", function () {
        catchTimeUpdate();
      });
    }
  }, []);

  return (
    <>
      <div className="bg-dark">
        <Stream
          controls
          className="min-vh-100 w-100"
          src={location.state.videoDetails.uid}
          autoplay
          startTime={
            location.state.btnType === "start" ? 0 : location.state.videoTime
          }
          streamRef={streamRef}
          onPause={catchTimeUpdate}
          onSuspend={catchTimeUpdate}
          onAbort={catchTimeUpdate}
        ></Stream>
        {error && <ErrorToast error={error}></ErrorToast>}
      </div>
    </>
  );
};

export default PlayVideo;
